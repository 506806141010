var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navbar-container d-flex content align-items-center"},[_c('ul',{staticClass:"nav navbar-nav d-xl-none"},[_c('li',{staticClass:"nav-item"},[_c('b-link',{staticClass:"nav-link",on:{"click":_vm.toggleVerticalMenuActive}},[_c('feather-icon',{attrs:{"icon":"MenuIcon","size":"21"}})],1)],1)]),_c('b-navbar-nav',{staticClass:"nav align-items-center ml-auto"},[_c('li',[_c('common-select',_vm._b({on:{"change":_vm.onUpdateCity}},'common-select',{
          disabled: _vm.userData.role !== 'admin',
          name: 'city_id',
          value: _vm.city_id,
          options: _vm.activeCities,
          placeholder: 'Выберите город',
          valueField: 'id',
          textField: 'name'
        },false))],1),_c('dark-Toggler'),_c('user-dropdown')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }