<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->

    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <!-- <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">

    </div> -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <li>
        <common-select
          v-bind="{
            disabled: userData.role !== 'admin',
            name: 'city_id',
            value: city_id,
            options: activeCities,
            placeholder: 'Выберите город',
            valueField: 'id',
            textField: 'name'
          }"
          @change="onUpdateCity"
        />
      </li>
      <dark-Toggler />

      <!-- <notification-dropdown /> -->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { AuthNameSpace, AuthActionTypes } from '@/store/modules/auth/types';
import { CityNameSpace, CityActionTypes } from '@/store/modules/city/types';
import { StaffNameSpace, StaffActionTypes } from '@/store/modules/staff/types';
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import { duration } from '@/config';
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
// import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown.vue'
import UserDropdown from './UserDropdown'
import { throttle } from 'lodash'
export default {
  components: {
    BLink,
    CommonSelect: () => import('@/components/common/common-select'),

    // Navbar Components
    BNavbarNav,
    DarkToggler,
    // NotificationDropdown,
    UserDropdown,
  },

  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },

  computed: {
    ...mapState(AuthNameSpace, {
      userData: 'userData',
      cities: 'cities',
    }),
    ...mapState(CityNameSpace, {
      cityCollection: 'cities',
      cityFullCollection: 'allCities',
    }),
    activeCities() {
      return this.cityFullCollection
    },
  },

  data() {
    return {
      city_id: null,
    }
  },

  async mounted() {
    await this.loadCityCollection()
    await this[CityActionTypes.LoadCityFullCollection]()
    this.setCityId()
    this.onUpdateCity()
  },

  methods: {
    ...mapActions(AuthNameSpace, {
      [AuthActionTypes.UpdateUserData]: AuthActionTypes.UpdateUserData,
      [AuthActionTypes.LoadCityCollection]: AuthActionTypes.LoadCityCollection,
    }),
    ...mapActions(CityNameSpace, {
      [CityActionTypes.LoadCityFullCollection]: CityActionTypes.LoadCityFullCollection,
    }),
    ...mapActions(StaffNameSpace, {
      [StaffActionTypes.UpdateCityStaff]: StaffActionTypes.UpdateCityStaff,
    }),
    loadCityCollection: throttle(async function() {
      await this[AuthActionTypes.LoadCityCollection]({ page: 1, page_size: 50 })
    }, duration),
    async onUpdateCity(context) {
      Object.assign(this.$data, context)
      this[AuthActionTypes.UpdateUserData]({ ...this.$data })
      this[StaffActionTypes.UpdateCityStaff]({ id: this.userData.id, ...this.$data })
      const user = JSON.parse(localStorage.getItem('userData'))
      user.city_id = this.city_id
      const json = JSON.stringify(user)
      localStorage.setItem('userData', json)
    },
    setCityId() {
      this.activeCities.find(item => item.id === this.userData.city_id) ? this.city_id = this.userData.city_id : this.city_id = null
    },
  },
  watch: {
    cities: {
      handler() {
        this.setCityId()
      },
      deep: true,
    },
  },
}
</script>
