<template>
  <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.full_name || userData.username }}
        </p>
        <span class="user-status">{{ userData.role }}</span>
      </div>
      <b-avatar
          size="40"
          :src="userData.avatar"
          variant="light-primary"
          badge
          class="badge-minimal"
          badge-variant="success"
      >
        <feather-icon
            v-if="!userData.fullName"
            icon="UserIcon"
            size="22"
        />
      </b-avatar>
    </template>


    <!-- <b-dropdown-item
        :to="{ name: 'pages-account-setting' }"
        link-class="d-flex align-items-center"
    >
      <feather-icon
          size="16"
          icon="SettingsIcon"
          class="mr-50"
      />
      <span>Settings</span>
    </b-dropdown-item>

    <b-dropdown-item
        :to="{ name: 'pages-faq' }"
        link-class="d-flex align-items-center"
    >
      <feather-icon
          size="16"
          icon="HelpCircleIcon"
          class="mr-50"
      />
      <span>FAQ</span>
    </b-dropdown-item> -->
    <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="logout"
    >
      <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
      />
      <span>Выход</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
import { LoaderIcon } from 'vue-feather-icons'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
      timer: { id: '', delay: 5000, response: null },
    }
  },
  mounted() {
    this.timer.id = setInterval(() => {
      this.permissionCheck()
    }, this.timer.delay);
  },
  destroyed() {
    clearInterval(this.timer.id)
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
    async permissionRequest() {
      try {
          const response = await this.$http({
            method: 'GET',
            url: 'role/get-permissions',
            data: { role: this.userData.role },
          })
          this.timer.response = response
      } catch (error) {
          console.log(error)
      }
    },
    async permissionCheck() {
      await this.permissionRequest()
      if (![200, 201, 202].includes(this.timer.response.status)) {
        this.timer.delay = 10000
      } else {
        const current = JSON.parse(localStorage.getItem('userData'))
        const newPermissions = this.timer.response.data.data.permisions

        if (current.permisions.length !== newPermissions.length) {
          const json = JSON.parse(localStorage.getItem('userData'))
          json.permisions = newPermissions
          localStorage.setItem('userData', JSON.stringify(json))
          this.$toast['warning']('Внимание! Ваши права доступа изменились. Страница будет перезагружена.', {
            position: 'bottom-right',
          })
          setTimeout(() => {
            window.location.href = '/order-calendar'
          }, 5000)
        }
      }
    },
  },
}
</script>
